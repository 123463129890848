import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import React from "react"
import useStations from "../utils/hooks/useStations"

const AboutDegreeDaysPage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="About Degree Days" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>About Degree Days</h1>

            <p>
              Degree days (DD) are, essentially, a mathematical way to calculate
              the accumulation of heating units over time. (Cooling units, i.e.
              chilling hours, can also be calculated, though this is not
              currently programmed into NEWA.) A brief description of DDs is
              available from the University of Massachusetts Extension Service:{" "}
              <a
                href="https://ag.umass.edu/landscape/fact-sheets/growing-degree-days-for-management-of-insect-pests-in-landscape"
                target="_blank"
                rel="noopener noreferrer"
              >
                Growing Degree Days for Management of Insect Pests in the
                Landscape
              </a>
            </p>

            <p>Keep in mind…</p>

            <ul>
              <li>
                NEWA serves many agricultural and horticultural commodities.
              </li>
              <li>
                There are several formulas that can be used to calculate degree
                days.
              </li>
              <li>
                Max and Min temperatures are collected during a ‘defined’
                24-hour period.
              </li>
            </ul>

            <p>
              Because DDs are a way of expressing heating units, entomologists,
              plant pathologists, horticulturists, and agronomists have utilized
              DD calculations to model the development (phenology) of arthropod
              pests, plant diseases, plants, crops, and weeds. For instance, we
              know that the best fit for explaining the development of
              ascospores of the apple scab fungus is using degree days
              calculated with a low cutoff temperature of 32°F. We also know
              that codling moth development does not progress below 50°F. This
              is also the case for most plants, thus DDs calculated with a base
              temperature (or low cutoff) of 50°F are commonly referred to as
              growing degree days, or GDDs.
            </p>

            <p>
              <strong>
                NEWA serves many agricultural and horticultural commodities
              </strong>{" "}
              - Several crop, pest, and disease phenology models are programmed
              into NEWA. Some rely solely on DD tables, some display results
              directly (DD accumulations are not apparent to the user), and some
              provide DD ranges when IPM decisions and interventions are needed
              (hanging traps, spray timings, etc.)
            </p>

            <p>
              Degree Days (DD) calculated in NEWA and the insect phenology, crop
              phenology and disease tools for which they were developed.
            </p>

            <table>
              <thead>
                <tr>
                  <th>Base Temp</th>
                  <th>Phenology Model</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>32° F</td>
                  <td>apple scab ascospore maturity</td>
                </tr>
                <tr>
                  <td>39° F</td>
                  <td>seed corn maggot</td>
                </tr>
                <tr>
                  <td>4° C</td>
                  <td>cabbage maggot</td>
                </tr>
                <tr>
                  <td>40° F</td>
                  <td>onion maggot</td>
                </tr>
                <tr>
                  <td>41° F</td>
                  <td>alfalfa and other legumes</td>
                </tr>
                <tr>
                  <td>43° F</td>
                  <td>obliquebanded leafroller, spotted tentiform leafminer</td>
                </tr>
                <tr>
                  <td>45° F</td>
                  <td>oriental fruit moth</td>
                </tr>
                <tr>
                  <td>47.14° F</td>
                  <td>grape berry moth</td>
                </tr>
                <tr>
                  <td>48° F</td>
                  <td>alfalfa weevil</td>
                </tr>
                <tr>
                  <td>50° F</td>
                  <td>
                    codling moth, plum curculio, apple maggot, San Jose scale,
                    blueberry maggot
                  </td>
                </tr>
                <tr>
                  <td>86°/50° F</td>
                  <td>corn development</td>
                </tr>
                <tr>
                  <td>55° F</td>
                  <td>fire blight shoot blight symptom development</td>
                </tr>
                <tr>
                  <td>14.3° C</td>
                  <td>brown marmorated stink bug</td>
                </tr>
              </tbody>
            </table>

            <p>
              Implementation of these models is guided by research and extension
              faculty at Cornell University, extension educators in Cornell
              Cooperative Extension, and experts at our partner land grant
              institutions. NEWA also provides a platform for stakeholder input
              to improve model performance and webpage results.
            </p>

            <p>
              For example, the "Results" pages on the Apple Insect Models on
              NEWA, are generated by accumulating temperature data for the
              location of interest, generating a DD value using Baskerville-Emin
              calculations (described below), and then comparing that total
              against a lookup table of DD ranges and populating messages on the
              Pest Status, Pest Development, and Pest Management boxes on the
              screen.
            </p>

            <p>
              <strong>
                There are several formulas that can be used to calculate degree
                days
              </strong>{" "}
              – In NEWA, historically, the simple Max-Min average formula is
              what has been used for DD calculations. This formula can readily
              be calculated by hand and was also included in many of the Cornell
              Pest Management Guidelines. The Baskerville-Emin (BE) formula uses
              a sine wave algorithm and results in more precise DD calculations.
              This formula was implemented in NEWA in ~2006.
            </p>

            <p>
              NEWA offers a choice of either the average (or simple) formula or
              the BE formula for use with the various base temperatures on the
              NEWA Degree Day Calculator and the NEWA All Weather Data Query
              webpages.
            </p>

            <p>
              Currently, the BE formula is being used in all the NEWA apple
              disease and apple insect phenology models that utilize DD
              accumulations. Drs. Cox and Agnello have chosen to use BE DDs
              because of their higher precision. Furthermore, BE DDs have been
              used in the entomology field observations in Geneva, NY, for the
              past 25 years or more.
            </p>

            <p>
              If you are comparing the Scaffolds “Upcoming Pest Events”
              tabulated DDs with what is tabulated for Geneva in NEWA, make sure
              you compare these with the BE DDs to get the best match. “Best
              match” because, having used two calculators to crunch an involved
              equation and come up with two answers that don’t exactly match, it
              is true that software programs can differ slightly in the way they
              handle rounding of decimal places, etc., which can create some
              differences in the mathematical answer.
            </p>

            <p>
              <strong>
                Max and Min temperatures are collected during a ‘defined’
                24-hour period
              </strong>{" "}
              - Another area that introduces variability in DD accumulations is
              how the 24-hour period is defined.  For some, the 24-hour day
              begins at midnight, and for some it ends at midnight. That is, in
              some systems midnight is 0:00, in some it is 24:00. In NEWA,
              midnight marks the end of the day. NEWA’s 24-hour period runs from
              12:01 AM (= 0:01) until 12 AM (midnight).
            </p>

            <p>
              Data is logged for NEWA’s database in a variety of ways.
              Therefore, some NEWA weather stations may miss the true Max and
              true Min temperature for a given day, because it might have
              occurred at 2:16 PM and not at a time when data was logged. Hence,
              this may add another source of variability!
            </p>

            <p>
              Daylight savings time can be problematic. Essentially, an hour is
              lost and then gained in the annual time continuum. NEWA will soon
              begin utilizing the same methodology as the National Weather
              Service (NWS) for dealing with this 23-hour-long day and
              25-hour-long day during the year.
            </p>

            <p>
              The NWS has Weather Observer sites reporting daily Max and Min
              temperatures. These sites collect data, once per day, at specified
              times, which can affect DD value calculations. Consider the time
              when you look at the values from your Max-Min thermometer and then
              clear them. If you look at these first thing in the morning and
              invariably at 5:00 AM, then you are collecting a true 24-hour Max
              and Min temperature for the period 5:00 AM the previous day until
              5:00 AM the current day. If you collect this data in the
              afternoon, the 24-hour period range would be different. Over time,
              climatologists have found that “afternoon” observations typically
              accumulate more DD’s than “morning” ones.
            </p>

            <p>
              The bottom line - when comparing DD data, keep in mind the sources
              of variability in DD accumulations. And don't sweat the
              discrepancies you find too much; like they say, “you can measure
              it with a micrometer, but what's the sense if you have to cut it
              out with a hatchet?” Nothing is more accurate than looking outside
              and seeing if you have green tip or counting the insects in your
              traps. We certainly don't expect an adult codling moth to pop out
              on the dot at 489 DD50 from January 1, or plum curculio to stop
              immigrating into the orchard at 308 DD50 from petal fall; there
              are simply too many sources of variability (e.g., in whose data
              one is using, how it was collected, in how representative a site,
              and at what point in time, etc.) to make this level of tracking
              practical.
            </p>

            <p>
              NEWA provides theoretical predictions and forecasts. The
              theoretical models predicting pest development or disease risk use
              the weather data collected (or forecasted) from the weather
              station location. These results should not be substituted for
              actual observations of plant growth stage, pest presence, and
              disease occurrence determined through scouting or insect pheromone
              traps.
            </p>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>Page updated June 2020 by J. Carroll and K. Eggleston</p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
          <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset 
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{" "}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutDegreeDaysPage
